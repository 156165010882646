<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="flag.FlagName" label="Hashtag" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-color-picker v-model="flag.FlagColor" label="Màu sắc" class="input-group--focused"  hide-canvas></v-color-picker>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                     <v-textarea v-model="flag.Note" label="Ghi chú" class="input-group--focused"></v-textarea>
                </v-flex>
                <v-flex lg4 md4 sm12>
                     <v-switch v-model="flag.IsStandby" label="Tag tự động" class="input-group--focused"></v-switch>
                </v-flex>
                <v-flex lg4 md4 sm12 v-if="flag.IsStandby">
                     <v-select v-model="flag.FlagType" :items="flag_types" item-text="name" item-value="value" label="Loại tag" class="input-group--focused"></v-select>
                </v-flex>
                <v-flex lg4 md4 sm12 v-if="flag.IsStandby && flag.FlagType <= 20">
                    <v-text-field v-model="flag.NumberOfDay" label="Số ngày trễ" type="number" step="1" class="input-group--focused"></v-text-field>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
export default {
    data() {
        return {
            flag_types: configs.FLAG_TYPE_LIST
        }
    },
    computed: {
        ...mapState({
            loading: state => state.flag.loading,
            flag: state => state.flag.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        }
    },
    watch: {
        modifyId() {
            this.refreshData();
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("flag/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("flag/setDetail", { data: {
                        ID: 0
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("flag/setDetail", {
                data: {
                    ID: this.flag.ID,
                    FlagName: this.flag.FlagName,
                    FlagColor: this.flag.FlagColor ? (this.flag.FlagColor.hex || this.flag.FlagColor) : '' ,
                    Note: this.flag.Note,
                    IsStandby: this.flag.IsStandby,
                    FlagType: this.flag.FlagType,
                    NumberOfDay: this.flag.NumberOfDay
                },
                id: this.flag.ID
            });
            window.getApp.$emit("APP_NAVIGATE", "FlagList");
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.flag.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>

<style>
</style>
